<template>
  <section id="dashboard-ecommerce">
    <b-card class="blog-edit-wrapper">
      <b-card-header style="background-color: #012951; color: #fffff">
        <b-card-title> Welcome To Control Panel </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <span class="label"> User Name: </span> {{ Userdata.name }}
          </b-col>
          <!-- <b-col  cols="12"> -->
          <!-- <span class="label"> Role:     </span>    {{Userdata.roles[0]}} -->
          <!-- </b-col> -->
          <b-col cols="12">
            <span class="label"> Latest login Time: </span>
            {{ Userdata.last_login_at }}
          </b-col>
          <b-col cols="12">
            <span class="label"> Latest login IP: </span>
            {{ Userdata.last_login_ip }}
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card class="blog-edit-wrapper W-100">
      <b-card-header
        style="background-color:#012951;color:#fffff"
      >
        <b-card-title>Statistics
        </b-card-title>
      </b-card-header>
      <b-card-body
        class="my-10"
        style="margin-top: 10px;"
      >
        <b-row>

          <b-col xl="3" md="12" sm="12">
            <b-card class="blog-edit-wrapper">
              <div class="text-center">
                <h4 class="mt-5">
                  Categories Count
                  <b-icon icon="grid" />
                </h4>
              </div>

              <b-card-body>
                <b-row class="text-center">
                  <b-col
                    cols="12"
                    class="count"
                  >
                    {{ Statsitic.categories_count }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="3" md="12" sm="12">
            <b-card class="blog-edit-wrapper">
              <div class="text-center">
                <h4 class="mt-5">
                  Classical Course Count
                  <b-icon icon="journal-bookmark" />
                </h4>
              </div>

              <b-card-body>
                <b-row class="text-center">
                  <b-col
                    cols="12"
                    class="count"
                  >
                    {{ Statsitic.classical_courses_count }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="3" md="12" sm="12">
            <b-card class="blog-edit-wrapper">
              <div class="text-center">
                <h4 class="mt-5">
                  Online Course Count
                  <b-icon icon="journal-bookmark-fill" />
                </h4>
              </div>

              <b-card-body>
                <b-row class="text-center">
                  <b-col
                    cols="12"
                    class="count"
                  >
                    {{ Statsitic.online_courses_count }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="3" md="12" sm="12">
            <b-card class="blog-edit-wrapper">
              <div class="text-center">
                <h4 class="mt-5">
                  Cities Count
                  <b-icon icon="map" />
                </h4>
              </div>

              <b-card-body>
                <b-row class="text-center">
                  <b-col
                    cols="12"
                    class="count"
                  >
                    {{ Statsitic.cities_count }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card class="blog-edit-wrapper W-100">
      <b-card-header
        style="background-color:#012951;color:#fffff"
      >
        <b-card-title>Schedules Count
        </b-card-title>
      </b-card-header>
      <b-card-body
        class="my-10"
        style="margin-top: 10px;"
      >
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label="Start Date"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Date"
                rules="required"
              >

                <flat-pickr
                  v-model="start_date"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label="End Date"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="end Date"
                rules="required"
              >

                <flat-pickr

                  v-model="end_date"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
        </b-row>
        <apexchart
 
          height="400"
          :options="chartOptions"
          :series="chartData"
        />
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    apexchart: VueApexCharts,
    BCardHeader,
    flatPickr,
    BCard,
    BCardTitle,
  },

  data() {
    return {
      Userdata: {},

      end_date: '',
      start_date: '',
      Statsitic: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
         
        },
        dataLabels: {
          enabled: true,
          style: {
            // colors: ["#64699a","#f6832d"]
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Count',
          },
        },
      },
      chartData: [],

    }
  },
  watch: {
    start_date() {
      this.getData()
    },
    end_date() {
      this.getData()
    },
  },
  created() {
    this.getData()
    this.$store.dispatch('setting/getInfo').then(response => {
      this.Userdata = response.data.data
    })
    this.$store.dispatch('setting/getStatsitic').then(response => {
      this.Statsitic = response.data.data
    })
  },
  methods: {
    getData() {
      this.chartOptions.xaxis.categories=[]
      window.dispatchEvent(new Event('resize'))
      this.$http.get(`/get-schedules-this-week?start=${this.start_date}&end=${this.end_date}
      `)
        .then(response => {
          window.dispatchEvent(new Event('resize'))
          console.log('stat', response.data)
          const categories = []
          const counts = []
          response.data.forEach(item => {
            this.chartOptions.xaxis.categories.push(item.city_name)
            counts.push(item.count)
          })

          // set the chart data and options
          // this.chartOptions.xaxis.categories = categories
          this.chartData = [{ name: 'Count', data: counts }]
          window.dispatchEvent(new Event('resize'))
         
          // const chart = new VueApexCharts(this.$el.querySelector('#bar-chart'), this.chartOptions);
          // chart.render()
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
h4.card-title {
  color: #fff;
}
.col-12 {
  margin: 15px;
}
.label {
  font-weight: 900;
  margin: 5px;
}
.count {
    font-size: 20px;
    font-weight: 600;
}
</style>
